import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';  
@Pipe({
  name: 'currencyPda'
})
export class CurrencyPdaPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }  
  transform(num: any, currencyCode: string, showSymbol: boolean, digits: string): any {
    let value = new CurrencyPipe(navigator.language).transform(num, currencyCode, showSymbol, digits);
    let lastDigit = '<span style="font-size: 60%;">' + value?.slice(-6).slice(0,-4) + '</span>';
    let firstDigit = value?.match(/\d/);
    let symbol = value?.slice(0, firstDigit?.index);
    let amount = value?.slice(firstDigit?.index);
    let amountLast = "$"+amount?.slice(0, -6) + lastDigit;
    // const sanitizedContent = DOMPurify.sanitize(amountLast);
    // return this.sanitizer.bypassSecurityTrustHtml(amountLast);   
    return amountLast;

  }

}
